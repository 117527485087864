<template>
    <div class="project">
        <div class="intro grid">
            <h1>{{$t('allUnited.title')}}</h1>
            <!-- <div class="cell">
                <p>{{$t('allUnited.intro')}}</p>
            </div> -->
            <div class="cell">
                <dl>
                    <dt>{{$t('project.client')}}</dt>
                    <dd>{{$t('allUnited.client')}}</dd>
                    <dt>{{$t('project.role')}}</dt>
                    <dd>{{$t('allUnited.role')}}</dd>
                    <dt>{{$t('project.timeframe')}}</dt>
                    <dd>2018 - 2020</dd>
                    <dt>{{$t('project.prototype')}}</dt>
                    <dd><a href="https://www.sketch.com/s/8e0e4fa7-60b8-4d55-ad65-c6fd0f0ae36e/v/Myx7Jw" class="button" target="_blank">All United Sketch prototype</a></dd>
                </dl>
            </div>
        </div>
        <!-- <div class="case">
            Case images
        </div> -->
        <OtherCases prev-route="/projects/fast-fluid" prev-title="fastFluid" next-route="/projects/hbn" next-title="hbn" />
    </div>
</template>
<script>
import OtherCases from '@/components/OtherCases.vue'

export default {
    components: {
        OtherCases
    }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/pages/_Project.scss';
</style>